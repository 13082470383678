// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cruwa-crw-js": () => import("./../../../src/pages/CruwaCRW.js" /* webpackChunkName: "component---src-pages-cruwa-crw-js" */),
  "component---src-pages-cruwa-js": () => import("./../../../src/pages/Cruwa.js" /* webpackChunkName: "component---src-pages-cruwa-js" */),
  "component---src-pages-flamco-js": () => import("./../../../src/pages/Flamco.js" /* webpackChunkName: "component---src-pages-flamco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mas-daf-js": () => import("./../../../src/pages/MasDaf.js" /* webpackChunkName: "component---src-pages-mas-daf-js" */),
  "component---src-pages-photo-video-js": () => import("./../../../src/pages/PhotoVideo.js" /* webpackChunkName: "component---src-pages-photo-video-js" */),
  "component---src-pages-reflex-js": () => import("./../../../src/pages/Reflex.js" /* webpackChunkName: "component---src-pages-reflex-js" */),
  "component---src-pages-wester-js": () => import("./../../../src/pages/Wester.js" /* webpackChunkName: "component---src-pages-wester-js" */),
  "component---src-pages-zilmet-js": () => import("./../../../src/pages/Zilmet.js" /* webpackChunkName: "component---src-pages-zilmet-js" */)
}

